import { CloseButton } from '@mybridge/ui/close-button';
import { Image } from '@mybridge/ui/image';
import { VideoPlayer } from '@mybridge/ui/video-player';
import { MediaSlider, MediaSliderItem } from '@mybridge/ui/media-slider';
import { useContext, useEffect, useState } from 'react';
import { fileToDataURL } from 'v4/lib/file';
import { PostFormContext } from '../context';
import { Box, Stack } from '@mybridge/ui/layout';
import { PostBoxContext } from 'v4/components/post/context';

export const AvailableMediaContainer = ({images, videos, gifs}) => {
  
  const [images_, setImages_] = useState([]);
  const [videos_, setVideos_] = useState([]);
  const [gifs_, setGifs_] = useState([]);

  useEffect(() => {
    if(images?.length){
      prepareImages();
    }
  }, [images]);

  useEffect(() => {
    if(videos?.length){
      prepareVideos();
    }
  }, [videos]);

  useEffect(() => {
    if(gifs?.length){
      prepareGifs();
    }
  }, [gifs]);

  const prepareVideos = async () => {
    const vids = [];
    for (let vid of videos) {
      vids.push(typeof vid === 'string' ? vid : typeof vid?.file === 'string' ? vid?.file : await fileToDataURL(vid));
    }fileToDataURL
    setVideos_(vids);
  };

  const prepareImages = async () => {
    const imgs = [];
    for (let img of images) {
      imgs.push(typeof img === 'string' ? img : typeof img?.file === 'string' ? img?.file : await fileToDataURL(img || img?.file));
    }
    setImages_(imgs);
  };

  const prepareGifs = async () => {
    const gs = [];
    for (let gif of gifs) {
      gs.push(gif?.images?.original?.url || gif);
    }
    setGifs_(gs);
  };

  return (
    <>
      {videos_?.length ? (
        <Box pos="relative" borderRadius="5" overflow="hidden">
          <VideoPlayer src={videos_?.[0]} />
        </Box>
      ) : (
        <>
          {images_?.length ? (
            <MediaSlider>
              {images_?.map?.((img, index) => (
                <MediaSliderItem
                  key={index}
                  minW="200px"
                  maxH="200px"
                  bg="blackAlpha.800"
                >
                  <Image maxH="300px" src={img} />
                </MediaSliderItem>
              ))}
            </MediaSlider>
          ) : (
            <>
              {gifs_?.length ? (
                // <Box maxH="200px" overflowY="auto" borderRadius="5">
                <Stack bg="blackAlpha.900" alignItems="center" pos="relative">
                  <Image maxH={'300px'} src={gifs_?.[0]} />
                  {/* </Box> */}
                </Stack>
              ) : (
                ''
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
